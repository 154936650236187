export const WebConfig = [
  // Sign in page depends on devices
  {
    path: '/',
    redirect: '/sign-in'
  },
  {
    path: '/',
    component: () => import('@/layouts/WebBlankLayout'),
    children: [
      {
        path: '/404',
        meta: {
          title: 'NotFound',
          code: 10000001
        },
        component: () => import('@/views/error/404')
      },
      {
        path: '/403',
        meta: {
          title: 'Deny',
          code: 10000001
        },
        component: () => import('@/views/error/403')
      },
      {
        path: '/500',
        meta: {
          title: 'Error',
          code: 10000001
        },
        component: () => import('@/views/error/500')
      },
      {
        path: 'application/:resource',
        component: () => import('@/views/system/Invitation')
      },
      {
        path: 'returning/:resource',
        component: () => import('@/views/system/Invitation')
      },
      {
        path: 'complete-information/:resource',
        component: () => import('@/views/system/Invitation')
      },
      {
        path: 'pending-application/:resource',
        component: () => import('@/views/system/Invitation')
      },
      {
        path: 'guidance', // region System with Blank
        component: () => import('@/views/guidance/Index')
      },
      // 邮件链接到协议、签字
      {
        path: 'signature/:resource',
        component: () => import('@/views/online-signature/LoadResource')
      }
    ]
  },
  {
    path: '/',
    component: () => import('@/layouts/WebAccountLayout'),
    children: [
      {
        path: 'welcome',
        component: () => import('@/views/system/WebWelcome')
      },
      {
        path: '/consent',
        component: () => import('@/views/system/WebWelcome')
      },
      {
        path: 'sign-in',
        component: () => import('@/views/system/SignIn')
      },
      {
        path: 'send-set-link',
        component: () => import('@/views/system/SendSetLink')
      },
      {
        path: 'create-account/:resource',
        component: () => import('@/views/system/CreateAccount')
      },
      {
        path: 'send-reset-link',
        component: () => import('@/views/system/SendResetLink')
      },
      {
        path: 'reset-password/:resource',
        component: () => import('@/views/system/ResetPassword')
      },
      {
        path: 'bank-statement-require',
        component: () => import('@/views/bank-statement/Requirement')
      }
    ]
  },
  // croc repayment
  {
    path: '/coupon',
    component: () => import('@/layouts/WebNavigationLayout'),
    children: [
      {
        path: 'verification/:identifier',
        component: () => import('@/views/collection/EmailVerification')
      },
      {
        path: 'overview',
        component: () => import('@/views/collection/PromotionPlan')
      },
      {
        path: 'schedule',
        component: () => import('@/views/collection/ScheduleNewPayment')
      }
    ]
  },
  // region Business
  {
    path: '/disbursement',
    component: () => import('@/layouts/WebNavigationLayout'),
    children: [
      {
        path: 'loan-application',
        name: 'loan-application',
        meta: {
          sameDayTip: true,
          title: 'Disbursement'
        },
        component: () => import('@/views/disbursement/application')
      },
      {
        path: 'loan-details',
        name: 'loan-details',
        meta: {
          sameDayTip: true,
          title: 'Loan Details'
        },
        component: () => import('@/views/disbursement/LoanDetail')
      },
      {
        path: 'signature',
        name: 'signature',
        meta: {
          sameDayTip: true,
          title: 'Signature'
        },
        component: () => import('@/views/disbursement/LoanAgreementSignature')
      }
    ]
  },
  {
    path: '/',
    component: () => import('@/layouts/WebNavigationLayout'),
    children: [
      {
        path: 'home',
        name: 'Home',
        meta: {
          title: 'Home'
        },
        component: () => import('@/views/business/Home')
      },
      {
        path: '/loan-agreement',
        name: 'Loan Agreement',
        meta: {
          title: 'Apply for New Loan'
        },
        component: () => import('@/views/online-signature/LoanAgreement')
      },
      {
        path: 'repayment/loan-details',
        name: 'Loan Details',
        meta: {
          title: 'Repayment'
        },
        component: () => import('@/views/repayment/RepaymentLoanDetails')
      },
      {
        path: 'change-password',
        name: 'Change Password',
        meta: {
          title: 'Change Password'
        },
        component: () => import('@/views/system/ChangePassword')
      },
      {
        path: 'upload-bank-statement',
        component: () => import('@/views/bank-statement/UploadBankStatement')
      }
    ]
  },
  {
    path: '/',
    component: () => import('@/layouts/WebBlankLayout'),
    children: [
      {
        path: '/notification/:code',
        component: () => import('@/views/system/Notification')
      },
      {
        path: '/simulator',
        component: () => import('@/views/system/Simulator')
      }
    ]
  }
]
